import React from 'react'
import updateAddressFragment from '@data/fragments/preferences/updateAddressFragment.main'
import withWebsiteId from '@helpers/websites/withWebsiteId'
import withGraphQL from '@packages/react-apollo-decorators/lib/withGraphQL'
import gql from 'graphql-tag'

import UpdateBasicOptions from './UpdateBasicOptions'

import styles from './styles.module.css'

export interface OptionsProps {
  preferences: any
  stores: any
  attentionClassName: string
  useBox: boolean
}

@withWebsiteId
@withGraphQL(
  gql`
    query getUserPreferences($websiteId: ID) {
      preferences: userPreferences(websiteId: $websiteId) {
        _id
        websiteId
        storeId
        placeId
        deliveryType
        paymentType
        deliverAt
        cashAmount
        time
        menuId
        ...updateAddressFragment
      }
      stores(websiteId: $websiteId) {
        items {
          _id
          label: name
          value: _id
          acceptServe
          acceptGo
          address {
            placeId
          }
        }
      }
    }
    ${updateAddressFragment}
  `,
  {loading: null}
)
export default class Options extends React.Component<OptionsProps> {
  render() {
    const {preferences, stores, attentionClassName, useBox} = this.props
    if (!preferences) return null
    return (
      <div className={useBox ? styles.box : styles.container}>
        {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ preferences: any; stores: any; useBox: boo... Remove this comment to see the full error message */}
        <UpdateBasicOptions
          preferences={preferences}
          stores={stores}
          useBox={useBox}
          attentionClassName={attentionClassName}
        />
      </div>
    )
  }
}
