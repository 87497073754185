import React from 'react'
import DeliveryType from '@components/fields/DeliveryType'
import Select from '@packages/justo-parts/lib/components/fields/Select'
import withMessage from '@packages/justo-parts/lib/decorators/withMessage'
import withMutation from '@packages/react-apollo-decorators/lib/withMutation'
import UpdateAddress from '@page-components/Checkout/Checkout/Delivery/Address/Update'
import StoreMessage from '@page-components/Order/Options/UpdateBasicOptions/StoreMessage'
import autobind from 'autobind-decorator'
import gql from 'graphql-tag'
import {NextRouter, withRouter} from 'next/router'
import {Field, Form} from 'simple-react-form'

import styles from './styles.module.css'

export interface UpdateBasicOptionsProps {
  router: NextRouter
  showMessage: (message: string) => void
  setPreferences: (...args: any[]) => void
  stores: any
  preferences: any
  attentionClassName: string
  useBox: boolean
}

@withMutation(gql`
  mutation setPreferences($websiteId: ID, $storeId: ID, $placeId: ID, $deliveryType: ID) {
    setUserPreferences(
      websiteId: $websiteId
      storeId: $storeId
      placeId: $placeId
      deliveryType: $deliveryType
    ) {
      _id
      storeId
      placeId
      websiteId
      deliveryType
      deliverAt
      time
      channel
      menuId
    }
  }
`)
@withMessage
export class UpdateBasicOptions extends React.Component<UpdateBasicOptionsProps> {
  state = {
    loading: null
  }

  @autobind
  async onChange(params) {
    try {
      if (!this.props.preferences.websiteId) {
        return this.props.router.replace({
          pathname: '/login',
          query: {nextPathname: this.props.router.pathname}
        })
      }
      this.setState({loading: true})

      await this.props.setPreferences(
        {
          ...this.props.preferences,
          ...params
        },
        {
          refetchQueries: ['getMyOrderPreferences', 'getUserPreferences']
        }
      )
    } catch (error) {
      this.props.showMessage(error)
    } finally {
      this.setState({loading: false})
    }
  }

  renderPlace() {
    const {deliveryType} = this.props.preferences
    if (deliveryType !== 'delivery') return
    return <UpdateAddress preferences={this.props.preferences} onChange={this.onChange} autoSave />
  }

  renderStore() {
    if (this.state.loading) return null

    const {stores = {items: []}, preferences = {}} = this.props
    const {deliveryType} = preferences
    if (deliveryType === 'delivery' || !deliveryType) {
      return <StoreMessage preferences={preferences} />
    }

    const filteredStores = stores.items.filter(store => {
      if (!store.address || !store.address.placeId) {
        return false
      }
      if (deliveryType === 'go' && store.acceptGo) {
        return true
      }
      if (deliveryType === 'serve' && store.acceptServe) {
        return true
      }
      return false
    })

    if (filteredStores.length === 1) {
      if (!this.props.preferences) return
      if (this.state.loading) return
      if (this.props.preferences.storeId !== filteredStores[0].value) {
        setTimeout(() => this.onChange({storeId: filteredStores[0].value}), 0)
      }
      return
    }

    return (
      <Field
        fieldName="storeId"
        label="Elige un local"
        placeholder="Selecciona un local"
        type={Select}
        options={filteredStores}
      />
    )
  }

  render() {
    return (
      <div className={styles.container}>
        <Form state={this.props.preferences} onChange={this.onChange}>
          <Field fieldName="deliveryType" type={DeliveryType} light={!this.props.useBox} />
          <div className={this.props.attentionClassName}>
            {this.renderPlace()}
            {this.renderStore()}
          </div>
        </Form>
      </div>
    )
  }
}

export default withRouter<UpdateBasicOptionsProps>(UpdateBasicOptions)
