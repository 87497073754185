import React, {useContext} from 'react'
import {AddressForm} from '@components/Address'
import ClientSuspense from '@components/ClientSuspense'
import Loading from '@components/Loading'
import SafeSuspense from '@components/SafeSuspense'
import SuspenseLoading from '@components/SuspenseLoading/Loading'
import AddressContext from '@helpers/addresses/AddressesContext'
import {AddressFormType, DeliveryTypes} from '@helpers/addresses/models'
import isServerSide from '@helpers/misc/isServerSide'
import sleep from '@helpers/misc/sleep'
import {EVENTS, sendPosthogData} from '@helpers/posthog'
import useResizedImageURL from '@hooks/useResizedImageURL'
import useScreenSize from '@hooks/useScreenSize'
import useShouldUseNewAddressesUI from '@hooks/useShouldUseNewAddressesUI'
import useState from '@hooks/useState'
import useWebsiteId from '@hooks/useWebsiteId'
import Container from '@packages/justo-parts/lib/components/Container'
import {useQuery_deprecated as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'
import get from 'lodash/get'
import Link from 'next/link'
import {useRouter} from 'next/router'

import Options from './Options'

import styles from './styles.module.css'

const OptionsWrapper = ({useBox = false}) => {
  const websiteId = useWebsiteId()
  const router = useRouter()
  const [attentionClassName, setAttention] = useState<string | void>()
  const shouldUseNewAddressesUI = useShouldUseNewAddressesUI()
  const {setDeliveryType} = useContext(AddressContext)

  const {preferences, website} = useQuery(
    gql`
      query startOrderingGetDeliveryOptions($websiteId: ID) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          websiteId
          placeId
          deliveryType
          storeId
        }
        website(websiteId: $websiteId) {
          _id
          deliveryTypes
        }
      }
    `,
    {websiteId},
    {fetchPolicy: 'cache-first'}
  )

  const {deliveryType, placeId, storeId} = preferences
  if (get(website, 'deliveryTypes', []).length === 0) return null

  const order = async () => {
    if (
      (deliveryType === 'go' && !storeId) ||
      (deliveryType === 'serve' && !storeId) ||
      (deliveryType === 'delivery' && !placeId)
    ) {
      setAttention('animated shake')
      await sleep(1000)
      setAttention('animated')
      return
    }
    sendPosthogData(EVENTS.clicked.addressComponentOrder)
    router.push('/pedir')
  }

  const button =
    deliveryType &&
    (deliveryType === 'serve' ? (
      <div>
        <Link href="/reservar">
          <a className={styles.button}>Sólo reservar una mesa</a>
        </Link>
        <a className={styles.button} onClick={order}>
          Pedir y reservar una mesa
        </a>
      </div>
    ) : (
      <a className={styles.button} onClick={order}>
        Pedir
      </a>
    ))

  return (
    <>
      {shouldUseNewAddressesUI ? (
        <SafeSuspense fallback={<SuspenseLoading />}>
          <AddressForm
            type={AddressFormType.DeliveryTypes}
            closeModal={() => {}}
            onSelectDeliveryType={(deliveryType: DeliveryTypes, isTheOnlyValue?: boolean) => {
              setDeliveryType(deliveryType)
              if (!isTheOnlyValue) router.push('/pedir?addressModal=true')
            }}
            websiteId={websiteId}
          />
          <a className={styles.button} onClick={() => router.push('/pedir')}>
            Pedir
          </a>
        </SafeSuspense>
      ) : (
        <>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Options useBox={useBox} attentionClassName={attentionClassName} />
          {button}
        </>
      )}
    </>
  )
}

export default function StartOrdering(props) {
  const {backgroundImage, title, useBox, ssrImage = null} = props
  const screenSize = useScreenSize()
  const isMobile = screenSize === 'mobile'

  const width = isMobile ? 400 : 1600
  const height = isMobile ? 300 : 1200

  const url = useResizedImageURL({fileId: backgroundImage._id, width, height}, {partial: true})

  const backgroundURL = url ? url : ssrImage?.resizedURL

  if (isServerSide() && !ssrImage?.resizedURL) {
    console.warn(
      'Could not fetch image for StartOrdering component while doing SSR. This should be fixed for an optimized experience.'
    )
    return <Loading />
  }

  if (useBox) {
    return (
      <div className={styles.containerBox} style={{backgroundImage: `url(${backgroundURL})`}}>
        <Container className={styles.boxContainer}>
          <div className={styles.box}>
            <h1 className={styles.titleBox}>{title}</h1>
            <ClientSuspense fallback={<Loading />}>
              <OptionsWrapper useBox />
            </ClientSuspense>
          </div>
        </Container>
      </div>
    )
  } else {
    return (
      <div className={styles.container} style={{backgroundImage: `url(${backgroundURL})`}}>
        <div className={styles.overlay} />
        <div className={styles.content}>
          <h1 className={styles.title}>{title}</h1>
          <br />
          <ClientSuspense fallback={<Loading />}>
            <OptionsWrapper />
          </ClientSuspense>
        </div>
      </div>
    )
  }
}
